<script>
  export let author = '@josefaidt'
  export let authorUrl = 'https://twitter.com/josefaidt'
</script>

<footer>
  <span>Made with <span id="heart">♥</span>
    <a href="https://twitter.com/josefaidt" target="_blank" rel="noreferrer noopener">@josefaidt</a>
  </span>
</footer>

<style>
  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  footer a {
    color: var(--theme-primary);
  }

  #heart {
    color: indianred;
  }
</style>